import * as React from "react"
import Layout from "../components/layout"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not Found">
      <main style={pageStyles}>
        <p>Sorry, the page you were looking for was not found.</p>
      </main>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
